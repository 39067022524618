* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --dark-brown: #260101;
  --burnt-sienna: #8C391B;
  --light-coffee: #BF814B;
  --sandstone: #F2CA7E;
  --teal: #1BCBF2;
  --powderblue: #A3C3DC;
}

html {
	scroll-behavior: smooth;
}

body {
  width: 100%;
  background-image: url(./assets/images/hero-bg.jpg);
  background-size: cover;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

main {
    padding-bottom: 8vh;    /* Footer height */
}

/* Header Styles */
header {
  width: 100%;
  background-color: var(--dark-brown);
  color: var(--sandstone);
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 20px 15px;
  flex-wrap: wrap
}


header h1 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 46px;
  width: 50%;
}

header nav  {
  width: 50%;
  margin: auto 0px;
}

header nav ul {
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px
}



header nav ul li span {
  text-decoration: none;
  color: var(--sandstone);
}

header nav ul li span:hover, footer ul li a:hover {
  color: palegoldenrod;
}

.navActive {
  color: palegoldenrod;
  font-weight: bold;
}



/* About Styles */

.about-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px;
}

.avatar {
  grid-column: span 1;
}

.avatar img {
  width: 80%;
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: -3px 3px 5px 0 #ccc;
  margin: 20px;
}

.about-text, .contact-text, .project-text {
  grid-column: span 2;
  background-color: var(--sandstone);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
  padding: 15px;
  margin: 20px;
  border-radius: 10px;
}

.about-text p {
  margin-top: 10px;
}

 /* My Projects Styles */
 .project-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 33vh);
  grid-gap: 20px;
  padding: 20px;
}

.card {
    
    width: 100%;
    box-shadow: -3px 3px 5px 0 #ccc;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.card1 {
  background-image: url(../src/assets/images/nanjidesuka.jpg);
}

.card2 {
  background-image: url(../src/assets/images/swatch.png);
}

.card3 {
  background-image: url(../src/assets/images/mind_decks.png);
}

.card4 {
  background-image: url(../src/assets/images/okamisca.png);
}

.card5 {
  background-image: url(../src/assets/images/techblog.png);
}

.card6 {
  background-image: url(../src/assets/images/tweet-tracks.png);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: var(--teal);
}

.card h3 {
  z-index: 2;
  background-color: var(--dark-brown);
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
  font-family: 'Josefin Sans', sans-serif;
  color: var(--sandstone);
  transition: .5s ease;
}

.card:hover h3 {
  color: var(--light-coffee);
}

.card:hover .overlay{
  opacity: 0.4;
}

/* Contact Styles */
.contact-grid {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.contact-grid p {
  margin-top: 10px;;
}

.contact-grid a {
  color: var(--dark-brown);
}

.contact-grid a:hover {
  font-weight: bolder;
}

/* Resume Styles */
.resume-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px;
}

.download {
  margin-bottom: 10px;
}

.download a{
  color: var(--dark-brown);
}

.download a:hover {
  color: var(--burnt-sienna);
}

/* Footer Styles */
footer ul li a { 
  font-size: 36px;
  text-decoration: none;
  color: var(--sandstone);
}

footer {
  position: absolute;
  bottom: 0;
  height: 8vh;
  width: 100%;
  background-color: var(--dark-brown);
  color: var(--sandstone);
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 20px 35px;
  flex-wrap: wrap;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 36px;
}

footer ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
}
/* Desktop/* Desktop Styles */
@media screen and (min-width: 851px) {
	
}


/* Tablet Styles */
@media screen and (max-width: 850px) {
  .project-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 200px);
    grid-gap: 20px;
    padding: 20px;
  }

  header h1 {
    font-size: 46px;
    width: 55%;
  }

  header nav {
      width: 45%;
      margin: auto 0px;
  }


  header nav ul li{
      font-size: 16px;
  }
  
  .contact-text {
    font-size: 1em;
  }

  .contact-text {
    height: auto;
  }
}

/* Mobile Styles */
@media screen and (max-width: 585px) {
	
  header {
      flex-direction: column;
  }
  
  header h1 {
  text-align: left;
  width: 100%
  }
  
  header nav {
      width: 100%;
      margin: auto 0px;
      justify-content: space-around;
  }
  
  .about-grid  {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 2;
    grid-gap: 20px;
    padding: 20px;
  }

  .project-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 6;
  }

  .about-text, .contact-text, .project-text {
    grid-column: span 1;
    font-size: 1.2em;
    padding: 15px;
    margin: 20px;
    border-radius: 10px;
  }

  .about-me, .projects, .contact-info{
      flex-direction: column;
  }
  
  .content-heading {
      flex: 0 100%;
      text-align: center;;
      border-right: none;
      border-bottom: 3px solid var(--teal);
  }
  
  .content-heading h2 {
      text-align: center;
  }

  .contact-grid {
    height: 600px;
    width: auto;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 20px;
    padding: 20px;
  }
  
  #contact-form, .contact-info, .resume-grid {
    font-size: .8em;
  }

  input {
    max-width: 50%;
  }

  textarea {
    max-width: 90%;
  }
}